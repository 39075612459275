import { useContext, useEffect, useState } from "react";
import { ScrollContext } from "../utils/useScroll";
import Hamburger from "hamburger-react";
import { useScreen } from "../utils/useScreen";

const links = [
  {
    label: "Overview",
    url: "#overview",
  },
  {
    label: "Games",
    url: "#games",
  },
  {
    label: "Contact",
    url: "#contact",
  },
];

export default function Navbar() {
  const [open, setOpen] = useState(false);
  const [hopen, setHopen] = useState(false);
  const [old, setOld] = useState(0);
  const { scrollY } = useContext(ScrollContext);
  const { isMobile } = useScreen();

  useEffect(() => {
    if (scrollY <= 10) {
      setOpen(false);
    } else {
      if (scrollY > old) {
        setOpen(true);
      } else {
        setOpen(false);
      }
    }
    setOld(scrollY);
  }, [scrollY]);

  return (
    <nav
      className={`fixed w-full z-[1000000] ${
        open ? "h-[70px] bg-black" : "h-[100px]"
      } flex justify-center transition-all text-white`}
    >
      <div className="container h-full flex justify-between items-center">
        {isMobile ? (
          <>
            <div className={`${open ? "h-10" : "h-16"} transition-all`}>
              <img
                src="/white_logo_tenjin.png"
                alt="logo"
                className="object-contain h-full"
              />
            </div>
            <Hamburger toggled={hopen} toggle={setHopen} />
            <div
              className={`${
                hopen ? "translate-x-0" : "translate-x-full"
              } transition-all absolute top-full left-0 flex flex-col justify-center gap-4 py-6 w-full bg-black`}
            >
              {links.map((link, index) => (
                <button
                  key={index}
                  onClick={() => {
                    const element = document.querySelector(link.url);
                    element.scrollIntoView({ behavior: "smooth" });
                  }}
                  className={`w-full text-center ${
                    index === 0 ? "bg-white text-black px-4 py-2" : ""
                  }`}
                >
                  {link.label}
                </button>
              ))}
            </div>
          </>
        ) : (
          <>
            <div className={`${open ? "h-10" : "h-16"} transition-all`}>
              <img
                src="/white_logo_tenjin.png"
                alt="logo"
                className="object-contain h-full"
              />
            </div>
            <div
              className={`flex gap-8 items-center ${
                open ? "text-base" : "text-xl"
              } transition-all`}
            >
              {links.map((link, index) => (
                <button
                  key={index}
                  onClick={() => {
                    const element = document.querySelector(link.url);
                    element.scrollIntoView({ behavior: "smooth" });
                  }}
                  className={`${
                    index === 0 ? "bg-white text-black px-4 py-2" : ""
                  }`}
                >
                  {link.label}
                </button>
              ))}
            </div>
          </>
        )}
      </div>
    </nav>
  );
}
