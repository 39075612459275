import { FaSteam } from "react-icons/fa";
import ReactPlayer from "react-player/youtube";

export default function Games() {
  return (
    <div id="games" className="relative h-screen text-white  bg-black">
      <div className="absolute top-0 left-0 w-full h-full z-[1] ">
        <img
          src="/image2.png"
          alt="Games"
          className="object-cover w-full h-full"
        />
      </div>

      <div className="flex lg:flex-row flex-col justify-between bg-black/20 h-full w-full z-[3] relative items-center lg:pt-0 pt-24">
        <div className="lg:w-1/2 w-full flex flex-col gap-6 lg:p-24 p-4 justify-center relative h-full z-[2]">
          {/* <span className="w-60 h-40">
          <img
            src="/ResortIslandLogo.png"
            alt="logo"
            className="object-contain h-full"
          />
        </span> */}
          <h3 className="lg:text-7xl text-4xl font-bold">
            Resort Island Simulator
          </h3>
          <p className="lg:text-2xl text-lg font-medium">
            Imagine a simulation game set in a dynamic, ever-evolving semi-open
            world, where you manage an island resort while striving to survive.
            Explore a world that changes with your actions, offering endless
            opportunities for discovery, all while balancing the challenges of
            island life.
            <br />
            <br />
            <span className="relative group">
              <a 
                href="https://store.steampowered.com/app/3613960/Resort_Island_Simulator/?beta=0" 
                target="_blank"
                rel="noopener noreferrer"
                className="inline-flex items-center gap-2 px-6 py-3 font-bold text-white bg-gradient-to-r from-blue-600 to-purple-600 rounded-lg transform transition-all duration-300 shadow-lg hover:shadow-xl hover:scale-105 hover:from-blue-500 hover:to-purple-500 overflow-hidden relative z-10"
              >
                <FaSteam className="text-xl" />
                ADD TO WISHLIST
                <span className="absolute inset-0 bg-gradient-to-r from-pink-500 to-yellow-500 opacity-0 group-hover:opacity-20 z-0 transition-opacity duration-300"></span>
                <span className="absolute -inset-1 bg-gradient-to-r from-blue-600 to-purple-600 rounded-lg blur opacity-30 group-hover:opacity-50 transition-opacity duration-300 animate-pulse"></span>
              </a>
            </span>
          </p>
          {/* <div className="flex gap-6 lg:pb-12">
          <a
            href=""
            className="bg-white text-black py-2 px-4 tracking-widest font-bold hover:text-orange-800 transition-all flex gap-4 items-center"
          >
            <FaSteam />
            <span>STEAM</span>
          </a>
          <a
            href=""
            className="bg-white text-black py-2 px-4 tracking-widest font-bold hover:text-orange-800 transition-all flex gap-4 items-center"
          >
            <SiEpicgames />
            <span>EPIC GAMES</span>
          </a>
        </div> */}
        </div>
        <div className="lg:w-1/2 w-full h-full flex justify-center items-center lg:p-12 p-4">
          <div className="aspect-video w-full">
            <ReactPlayer
              url="https://www.youtube.com/watch?v=dqhREvtIhj8"
              width={"100%"}
              height={"100%"}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
