import { FaYoutube, FaInstagram, FaLinkedin } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";

export default function Contact() {
  return (
    <div id="contact" className="bg-black text-white flex justify-center lg:py-32 py-12">
      <div className="container flex flex-col lg:gap-12 gap-3">
        <h4 className="tracking-widest font-bold">CONTACT</h4>
        <div className="flex justify-between lg:flex-row flex-col lg:gap-8 gap-6">
          <div className="flex flex-col gap-2 lg:w-[20%] w-full">
            <a
              href="mailto:media@tenjingames.com"
              className="font-light tracking-wide hover:underline"
            >
              media@tenjingames.com
            </a>
            <p>Istanbul/Turkey</p>
          </div>
          {/* <div className="flex flex-col gap-8 w-[20%]">
            <div className="flex flex-col gap-4">
              <p className="font-semibold text-sm tracking-wide">
                TECHNICAL SUPPORT
              </p>
              <p className="font-light tracking-wide">help</p>
            </div>
            <div className="flex flex-col gap-4">
              <p className="font-semibold text-sm tracking-wide">
                PRESS INQUIRIES
              </p>
              <p className="font-light tracking-wide">help</p>
            </div>
          </div> */}
          <div className="flex flex-col gap-2 lg:w-[20%] w-full">
            <p className="font-semibold text-sm tracking-wide">FOLLOW US</p>
            <a
              href="https://www.youtube.com/@TenjinGames"
              rel="noreferrer"
              target="_blank"
              className="flex gap-1 items-center"
            >
              <FaYoutube color="white" size={24} />
              <p className="font-[400] tracking-wide hover:underline">
                Youtube
              </p>
            </a>
            <a
              rel="noreferrer"
              target="_blank"
              href="https://x.com/TenjinGames"
              className="flex gap-1 items-center"
            >
              <FaXTwitter color="white" size={24} />
              <p className="font-[400] tracking-wide hover:underline">X</p>
            </a>
            <a
              rel="noreferrer"
              target="_blank"
              href="https://www.instagram.com/tenjingames/"
              className="flex gap-1 items-center"
            >
              <FaInstagram size={24} color="white"/>
              <p className="font-[400] tracking-wide hover:underline">
                Instagram
              </p>
            </a>
            <a
              rel="noreferrer"
              target="_blank"
              href="https://www.linkedin.com/company/tenjingames/"
              className="flex gap-1 items-center"
            >
              <FaLinkedin size={24} color="white"/>
              <p className="font-[400] tracking-wide hover:underline">
                LinkedIn
              </p>
            </a>
          </div>
        </div>
        <p className="text-center mt-4">© 2024 TENJIN GAMES</p>
      </div>
    </div>
  );
}
